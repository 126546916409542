import React, { useState, useEffect, useRef } from "react";
import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItemButton,
} from "@mui/material";
import {
    Close,
    ContentCopy,
    DataObject,
    SimCardDownload,
} from "@mui/icons-material";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import MainHeader from "../MainHeader";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { toast } from "react-toastify";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: theme.palette.text.secondary,
    },
    denseIconButton: {
        color: "#ffffff",
    },
    listItemButton: {
        borderRadius: 4,
    },
}));

const ExportMsgsModal = ({ modalOpen, onClose, ticketId }) => {
    const classes = useStyles();
    const [ticketMessages, setTicketMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const currentTicketId = useRef(ticketId);
    const [pageNumber, setPageNumber] = useState(1);


    useEffect(() => {
        const fetchMessages = async () => {
          if (loading)return;
          setLoading(true);
          try {
            const { data } = await api.get("/messages/" + ticketId, {
              params: { pageNumber, showAllMessages: false },
            });
    
            if (currentTicketId.current === ticketId) {
              // Concatena as mensagens novas às mensagens existentes
              setTicketMessages((prevMessages) => [...data.messages,...prevMessages]);
    
              if (data.hasMore) {
                // Se ainda houver mais mensagens, incremente o número da página
                setHasMore(true);
                setPageNumber((prevPageNumber) => prevPageNumber + 1);
              } else {

                // Se não houver mais mensagens, defina hasMore como false
                setHasMore(false);
              }
            }
          } catch (err) {
            toastError(err);
          }
          setLoading(false);
        };
    
        // Verifique se deve carregar mais mensagens apenas se o modal estiver aberto, ainda houver mais mensagens e não estiver carregando
        if (modalOpen && hasMore && !loading) {
          fetchMessages();
        }
      }, [ticketId, modalOpen, pageNumber, loading, hasMore]);
    
      const handleClose = () => {
        onClose();
        setTicketMessages([]);
        setPageNumber(1); // Reset para a primeira página ao fechar o modal
        setHasMore(true); // Reset para true ao fechar o modal
      };

    const copyToClipboard = () => {
        const messagesText = ticketMessages.map((message) => `[${message.createdAt}]${message.fromMe ? "Você" : message.contact.name}:${message.mediaType != "chat" ? `[${message.mediaType}]${message.body}` : message.body}`).join("\n");
        navigator.clipboard.writeText(messagesText).then(() => {
            toast.success(i18n.t("exportMsgsModal.toasts.Clipboardsuccess"));
        });
    };

    const exportToTxt = () => {
        const messagesText = ticketMessages.map((message) => `[${message.createdAt}]${message.fromMe ? "Você" : message.contact.name}:${message.mediaType != "chat" ? `[${message.mediaType}]${message.body}` : message.body}`).join("\n");
        const blob = new Blob([messagesText], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `messages_${ticketId}.txt`;
        a.click();
        URL.revokeObjectURL(url);
        toast.success(i18n.t("exportMsgsModal.toasts.TXTsuccess"));
    };

    const exportToJson = () => {
        const formattedMessages = ticketMessages.map((message) => ({
            createdAt: message.createdAt,
            id: message.id,

            sender: message.fromMe ? "Você" : message.contact.name,
            message: {
                text: message.body,
                mediaUrl: message.mediaUrl,
                type: message.mediaType,
                quotedMsgId: message.quotedMsgId,
            }
        }));

        const jsonMessages = JSON.stringify(formattedMessages, null, 2);

        const blob = new Blob([jsonMessages], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `messages_${ticketId}.json`;
        a.click();
        URL.revokeObjectURL(url);

        toast.success(i18n.t("exportMsgsModal.toasts.JSONsuccess"));
    };

    return (
        <Dialog open={modalOpen} onClose={handleClose}>
            <MainHeader>
                <DialogTitle color="textPrimary" id="form-dialog-title">
                    {i18n.t("exportMsgsModal.title",{ticketId})}
                </DialogTitle>
                <MainHeaderButtonsWrapper>
                    <IconButton
                        onClick={handleClose}
                        className={classes.iconButton}
                    >
                        <Close />
                    </IconButton>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <DialogContent dividers>
                {loading && <div style={{display:"flex", gap:4,aligItems:"center"}}><CircularProgress size={24}/>{i18n.t("exportMsgsModal.loading")}</div>}
                <List disablePadding>
                    <ListItemButton
                        disabled={loading|| hasMore}
                        onClick={copyToClipboard}
                        className={classes.listItemButton}
                    >
                        <Avatar sx={{ bgcolor: "#414141", margin: "0 4px" }}>
                            <ContentCopy className={classes.denseIconButton} />
                        </Avatar>
                        {i18n.t("exportMsgsModal.copyToClipboard")}
                    </ListItemButton>
                    <ListItemButton
                        disabled={loading|| hasMore}
                        onClick={exportToTxt}
                        className={classes.listItemButton}
                    >
                        <Avatar sx={{ bgcolor: "#4CAF50", margin: "0 4px" }}>
                            <SimCardDownload className={classes.denseIconButton} />
                        </Avatar>
                        {i18n.t("exportMsgsModal.exportToTxt")}
                    </ListItemButton>
                    <ListItemButton
                        disabled={loading|| hasMore}
                        onClick={exportToJson}
                        className={classes.listItemButton}
                    >
                        <Avatar sx={{ bgcolor: "#FBC02D", margin: "0 4px" }}>
                            <DataObject sx={{ color: "#424242" }} />
                        </Avatar>
                        {i18n.t("exportMsgsModal.exportToJson")}
                    </ListItemButton>
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default ExportMsgsModal;
