import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { i18n } from "../../translate/i18n";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
        minWidth: 200
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    inlineWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
        gap: 4
    },
    multiLine: {
        display: "flex",
        gap: 4,
        flex: 1,
    },
    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    trashIcon: {
        color: theme.palette.secondary.main
    },
}));

const models = [
    'gpt-4-0125-preview',
    'gpt-4-turbo-preview',
    'gpt-4-1106-preview',
    'gpt-4-vision-preview',
    'gpt-4',
    'gpt-4-0314',
    'gpt-4-0613',
    'gpt-4-32k',
    'gpt-4-32k-0314',
    'gpt-4-32k-0613',
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-16k',
    'gpt-3.5-turbo-0301',
    'gpt-3.5-turbo-0613',
    'gpt-3.5-turbo-1106',
    'gpt-3.5-turbo-0125',
    'gpt-3.5-turbo-16k-0613'
];

const IntegrationSchema = Yup.object().shape({
    type: Yup.string().required("Obrigatório!"),
    name: Yup.string()
        .max(100, "Muito longo!")
        .required("Obrigatório!"),
    apiKey: Yup.string().required("Obrigatório"),
    model: Yup.string().required("Obrigatório").oneOf(models, 'Modelo não corresponde a nenhum modelo válido'),
    instruction: Yup.string().max(500, "Muito longo!"),
    email: Yup.string().email("Email inválido!").max(50, "Muito longo!"),
    name: Yup.string()
        .max(100, "Muito longo!"),
    temperature: Yup.number().required("Obrigatório").test("Check-temperature-value",
        "Valores aceitos somente entre 0 e 1", (value) => value <= 1 && value >= 0),
    maxTokens: Yup.number().integer("Somente números inteiros").required("Obrigatório"),
    historyLimit: Yup.number().integer("Somente números inteiros").required("Obrigatório"),
    onlyTicket:Yup.boolean(),
});



const IntegrationModal = ({ open, onClose, integrationId, initialValues }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const initialState = {
        type: "openai",
        name: "",
        model: "gpt-4-0125-preview",
        apiKey: "",
        instructions: "",
        assName: "",
        temperature: 1,
        maxTokens: 256,
        historyLimit: 30,
        onlyTicket:true,
    };
    const [integration, setIntegration] = useState(initialState);


    useEffect(() => {
        const fetchIntegration = async () => {
            if (!integrationId) return;
            try {
                const { data } = await api.get(`/integrations/${integrationId}`);
                setIntegration(data);
            } catch (err) {
                setIntegration(initialState);
                toastError(err);
            }
        };

        fetchIntegration();
    }, [integrationId, open, initialValues]);

    const handleClose = () => {
        onClose();
        setIntegration(initialState);
    }

    const handleSaveIntegration = async (values) => {
        try {
            if (integrationId) {
                await api.put(`/integrations/${integrationId}`, values);
            } else {
                await api.post("/integrations", values);
            }
            toast.success(i18n.t("integrationModal.success"));
        } catch (err) {
            toastError(err);
        }
        handleClose();
    }
    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle
                    id="form-dialog-title"
                    color="textPrimary"
                >
                    {integrationId
                        ? `${i18n.t("integrationModal.title.edit")}`
                        : `${i18n.t("integrationModal.title.add")}`
                    }
                </DialogTitle>
                <Formik
                    initialValues={integration}
                    enableReinitialize={true}
                    validationSchema={IntegrationSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveIntegration(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <div style={{ display: "flex", gap: 4 }}>
                                    <FormControl
                                        className={classes.multiLine}
                                        variant="outlined"
                                        margin="dense"
                                        error={Boolean(errors.type)}
                                        disabled
                                    >
                                        <InputLabel>{i18n.t("integrationModal.form.type")}</InputLabel>
                                        <Field
                                            label={i18n.t("integrationModal.form.type")}
                                            as={Select}
                                            color="primary"
                                            name="type"
                                        >
                                            <MenuItem value={'openai'}>{i18n.t("integrationModal.form.options.openai")}</MenuItem>
                                            <MenuItem value={'api'}>{i18n.t("integrationModal.form.options.api")}</MenuItem>
                                            <MenuItem value={'webhhok'}>{i18n.t("integrationModal.form.options.webhook")}</MenuItem>
                                        </Field>
                                        <FormHelperText>{errors.type}</FormHelperText>
                                    </FormControl>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationModal.form.name")}
                                        name="name"
                                        autoFocus
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.multiLine}
                                    />
                                </div>
                                <div style={{ display: "flex", gap: 4 }}>
                                    <FormControl
                                        className={classes.multiLine}
                                        variant="outlined"
                                        margin="dense"
                                        error={Boolean(errors.model)}
                                    >
                                        <InputLabel>{i18n.t("integrationModal.form.model")}</InputLabel>
                                        <Field
                                            label={i18n.t("integrationModal.form.model")}
                                            as={Select}
                                            color="primary"
                                            name="model"
                                        >
                                            {models.map(model =>
                                                <MenuItem value={model}>{model}</MenuItem>
                                            )}
                                        </Field>
                                        <FormHelperText>{errors.model}</FormHelperText>
                                    </FormControl>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationModal.form.apiKey")}
                                        name="apiKey"
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword((e) => !e)}
                                                        size="large">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={touched.apiKey && Boolean(errors.apiKey)}
                                        helperText={touched.apiKey && errors.apiKey}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.multiLine}
                                    />
                                </div>
                                <Field
                                    as={TextField}
                                    label={i18n.t("integrationModal.form.instructions")}
                                    name="instructions"
                                    error={touched.instructions && Boolean(errors.instructions)}
                                    helperText={touched.instructions && errors.instructions}
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    multiline
                                    placeholder="Por favor, insira o conteúdo completo que deseja que o atendente virtual processe. Este texto deve incluir todas as informações relevantes que você gostaria que o atendente virtual compartilhasse ou respondesse. Certifique-se de fornecer detalhes claros e completos, incluindo endereços, números de contato, descrições de serviços ou qualquer outra informação relevante para a interação com o usuário. Este texto será usado para alimentar a conversa com o atendente virtual da API da OpenAI."
                                    rows={4}
                                />
                                <div style={{ display: "flex", gap: 4 }}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationModal.form.temperature")}
                                        name="temperature"
                                        type="number"
                                        error={touched.temperature && Boolean(errors.temperature)}
                                        helperText={touched.temperature && errors.temperature}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.multiLine}
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationModal.form.maxTokens")}
                                        name="maxTokens"
                                        type="number"
                                        error={touched.maxTokens && Boolean(errors.maxTokens)}
                                        helperText={touched.maxTokens && errors.maxTokens}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.multiLine}
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationModal.form.historyLimit")}
                                        name="historyLimit"
                                        type="number"
                                        error={touched.historyLimit && Boolean(errors.historyLimit)}
                                        helperText={touched.historyLimit && errors.historyLimit}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.multiLine}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Field
                                            name="onlyTicket"
                                                as={Switch}
                                                color="primary"
                                                checked={values.onlyTicket}
                                            />
                                        }
                                        label={i18n.t("integrationModal.form.onlyTicket")}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("integrationModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {integrationId
                                        ? `${i18n.t("integrationModal.buttons.okEdit")}`
                                        : `${i18n.t("integrationModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}
export default IntegrationModal;