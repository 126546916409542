import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { i18n } from '../../translate/i18n';
import { useState } from 'react';
import toastError from '../../errors/toastError';
import { toast } from 'react-toastify';

const DashboardFilters = ({ values, onChange, ...props }) => {
    const { classes } = props;
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().slice(0, 10);

    const initialState = { start: firstDayOfMonth, end: today.toISOString().slice(0, 10), status: '' }
    //const [filter, setFilter] = useState(initialState);

    const handleChange = (value, name) => {
        switch (name) {
            case ('date-start'):
                if (values.end && value > values.end) {
                    value = values.end;
                    toast.info(i18n.t("dashboard.errors.dateMax"));
                }
                onChange({ ...values, start: value });
                break;
            case ('date-end'):
                if (value < values.start) {
                    value = values.start;
                    toast.info(i18n.t("dashboard.errors.dateMin"));
                }
                onChange({ ...values, end: value });
                break;
            case ('status-select'):
                onChange({ ...values, status: value });
                break;
            default:
                toastError(i18n.t("dashboard.errors.generic"));
                break;
        }
    }

    return (
        <div style={{display:"flex",flexWrap:"wrap", gap:4}}>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                margin="dense"
            >
                <DatePicker
                    onChange={(value) => handleChange(value, "date-start")}
                    format="dd/MM/yyyy"
                    value={new Date(values.start)}
                    maxDate={new Date(values.end)}
                    label={i18n.t("dashboard.filters.startDate")}
                    />
            </FormControl>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                margin="dense"
                >
                <DatePicker
                    onChange={(value) => handleChange(value, "date-end")}
                    format="dd/MM/yyyy"
                    value={new Date(values.end)}
                    minDate = {new Date(values.start)}
                    label={i18n.t("dashboard.filters.endDate")}
                    />
            </FormControl>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                margin="dense"
            >
                <InputLabel id="status-selection-input-label">
                    {i18n.t("dashboard.filters.status")}
                </InputLabel>
                <Select
                    label="Status"
                    labelId="status-selection-input-label"
                    id="status-select"
                    name="status-select"
                    value={values.status}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    variant="outlined"
                    className={classes.settingOption}
                    >
                    <MenuItem value=''>Todos</MenuItem>
                    <MenuItem value="pending">{i18n.t("dashboard.messages.waiting.title")}</MenuItem>
                    <MenuItem value="open">{i18n.t("dashboard.messages.inAttendance.title")}</MenuItem>
                    <MenuItem value="closed">{i18n.t("dashboard.messages.closed.title")}</MenuItem>
                </Select>
            </FormControl>
        </LocalizationProvider>
                    </div>
    );

}
export default DashboardFilters;