import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const toastError = err => {
	const errorMsg = err.response?.data?.message || err.response?.data?.error;
	const errorContent = err.response?.data?.content
	if (errorMsg) {
		if (i18n.exists(`backendErrors.${errorMsg}`)&&errorContent) {
			toast.error(i18n.t(`backendErrors.${errorMsg}`,{content:errorContent}), {
				toastId: errorMsg,
			});
		}else if(i18n.exists(`backendErrors.${errorMsg}`)&&!errorContent) {
			toast.error(i18n.t(`backendErrors.${errorMsg}`), {
				toastId: errorMsg,
			});
		} else {
			toast.error(errorMsg, {
				toastId: errorMsg,
			});
		}
	} else {
		console.error(err)
		toast.error(i18n.t(`backendErrors.GENERIC_ERROR`));
	}
};

export default toastError;
