import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CircularProgress from "@mui/material/CircularProgress";


import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import TagContactManager from "../TagContactManager";
import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import useQueues from "../../hooks/useQueues";
import { Add } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",

	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	inlineWrapper: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		flexWrap: "wrap",
		gap: 4
	},
	multiLine: {
		display: "flex",
		gap: 4,
		flex: 1,
	},
	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	trashIcon: {
		color: theme.palette.secondary.main
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.max(100, "Muito longo!")
		.required("Obrigatório!"),
	number: Yup.string().min(8, "Muito curto!").max(50, "Muito longo!").required("Obrigatório"),
	company: Yup.string().max(100, "Muito longo!"),
	email: Yup.string().email("Email inválido!").max(50, "Muito longo!"),
	obs: Yup.string().max(1000, "Muito longo!"),
	chatbot: Yup.number().required("Obrigatório"),
	toQueue: Yup.number().nullable().when("chatbot", (chatbot, schema) => {
		if (chatbot[0] === 2) {
			return schema.required("Obrigatório");
		}
		return schema;
	})
});

const ContactModal = ({ open, onClose, contactId, initialValues }) => {
	const classes = useStyles();
	const [queues, setQueues] = useState([]);
	const { findAll: findAllQueues } = useQueues();

	const initialState = {
		name: "",
		number: "",
		company: "",
		email: "",
		obs: "",
		tags: [],
		chatbot: 1,
		toQueue: undefined
	};

	const [contact, setContact] = useState(initialState);

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setQueues(list);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (!contactId) return;
			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				setContact(data);
			} catch (err) {
				setContact(initialState);
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleSaveContact = async (values) => {
		try {
			if (contactId) {
				await api.put(`/contacts/${contactId}`, values);
			} else {
				await api.post("/contacts", values);
			}
			toast.success(i18n.t("contactModal.success", { type: contact.isGroup ? i18n.t("contactModal.group") : i18n.t("contactModal.contact") }));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg">
				<DialogTitle
					id="form-dialog-title"
					color="textPrimary"
					
				>
					{contactId
						? `${i18n.t("contactModal.title.edit", { type: contact.isGroup ? i18n.t("contactModal.group") : i18n.t("contactModal.contact") })}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div style={{ height: "55vh" }}>
									<Typography variant="subtitle1" gutterBottom>
										{i18n.t("contactModal.form.mainInfo", { type: contact.isGroup ? i18n.t("contactModal.group") : i18n.t("contactModal.contact") })}
									</Typography>
									{contact.isGroup ?
										<div>
											<Field
												as={TextField}
												label={i18n.t("contactModal.form.name")}
												name="name"
												autoFocus
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												className={classes.textField}
											/>
											<Field
												as={TextField}
												label={i18n.t("contactModal.form.company")}
												name="company"
												error={touched.company && Boolean(errors.company)}
												helperText={touched.company && errors.company}
												variant="outlined"
												margin="dense"
												className={classes.textField}
											/>
										</div>
										: <>
											<div>
												<Field
													as={TextField}
													label={i18n.t("contactModal.form.name")}
													name="name"
													autoFocus
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													label={i18n.t("contactModal.form.number")}
													name="number"
													error={touched.number && Boolean(errors.number)}
													helperText={touched.number && errors.number}
													placeholder="5513912344321"
													variant="outlined"
													margin="dense"
												/>
											</div>
											<div>
												<Field
													as={TextField}
													label={i18n.t("contactModal.form.company")}
													name="company"
													error={touched.company && Boolean(errors.company)}
													helperText={touched.company && errors.company}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													label={i18n.t("contactModal.form.email")}
													name="email"
													error={touched.email && Boolean(errors.email)}
													helperText={touched.email && errors.email}
													placeholder="email777@example.com"
													margin="dense"
													variant="outlined"
												/>
											</div>
										</>}

									<Typography
										style={{ marginBottom: 8, marginTop: 12 }}
										variant="subtitle1"
									>
										{i18n.t("contactModal.form.extraInfo")}
									</Typography>

									<FieldArray name="extraInfo">
										{({ push, remove }) => (
											<>
												{values.extraInfo &&
													values.extraInfo.length > 0 &&
													values.extraInfo.map((info, index) => (
														<div
															className={classes.extraAttr}
															key={`${index}-info`}
														>
															<Field
																as={TextField}
																label={i18n.t("contactModal.form.extraName")}
																name={`extraInfo[${index}].name`}
																variant="outlined"
																margin="dense"
																className={classes.textField}
															/>
															<Field
																as={TextField}
																label={i18n.t("contactModal.form.extraValue")}
																name={`extraInfo[${index}].value`}
																variant="outlined"
																margin="dense"
																className={classes.textField}
															/>
															<IconButton
																className={classes.trashIcon}
																size="small"
																onClick={() => remove(index)}
															>
																<DeleteOutlineIcon />
															</IconButton>
														</div>
													))}
												<div className={classes.extraAttr}>
													<Button
														style={{ flex: 1, marginTop: 8 }}
														variant="outlined"
														color="primary"
														onClick={() => push({ name: "", value: "" })}
														startIcon={<Add />}
													>
														{`${i18n.t("contactModal.buttons.addExtraInfo")}`}
													</Button>
												</div>
											</>
										)}
									</FieldArray>
									<Field
										as={TextField}
										label={i18n.t("contactModal.form.obs")}
										name="obs"
										error={touched.obs && Boolean(errors.obs)}
										helperText={touched.obs && errors.obs}
										fullWidth
										margin="dense"
										variant="outlined"
										multiline
										rows={4}
									/>
									<TagContactManager contactId={contactId} contactData={values} onChange={(tags) => values.tags = tags} />
									{!contact.isGroup &&
										<div className={classes.inlineWrapper}>
											<FormControl
												className={classes.multiLine}
												variant="outlined"
												margin="dense"
												error={Boolean(errors.chatbot)}
											>
												<InputLabel>{i18n.t("Chatbot")}</InputLabel>
												<Field
													label={i18n.t("Chatbot")}
													as={Select}
													color="primary"
													name="chatbot"
												>
													<MenuItem value={0}>{i18n.t("Desativado")}</MenuItem>
													<MenuItem value={1}>{i18n.t("Habilitado")}</MenuItem>
													<MenuItem value={2}>{i18n.t("Encaminhar para fila")}</MenuItem>
												</Field>
												<FormHelperText>{errors.chatbot}</FormHelperText>
											</FormControl>
											{values.chatbot === 2 &&
												<FormControl
													className={classes.multiLine}
													variant="outlined"
													margin="dense"
													error={Boolean(errors.toQueue)}
												>
													<InputLabel>{i18n.t("Fila")}</InputLabel>
													<Field
														label={i18n.t("Fila")}
														as={Select}
														color="primary"
														name="toQueue"
													>
														{queues.map((queue) => (
															<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
														))}
													</Field>
													<FormHelperText>{errors.toQueue}</FormHelperText>

												</FormControl>
											}

										</div>

									}
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{contactId
										? `${i18n.t("contactModal.buttons.okEdit")}`
										: `${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactModal;
