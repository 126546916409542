import React, { useEffect, useState, useContext } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";
import { WhatsApp, Edit, ScheduleSend, LocalOfferOutlined } from "@mui/icons-material";
import ContactModal from "../ContactModal";
import ScheduleModal from "../ScheduleModal";
import { Avatar, Button, IconButton, Typography, Popover, Chip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import TagsWrapper from "../TagsWrapper";

const useStyles = makeStyles(theme => ({
    contactsWrapper: {
        padding: 5,
        display: "flex",
        flexWrap: "wrap",
        //height: "100%",
        overflowY: "scroll",
        alignItems: "center",
        justifyContent: "center"
    },
    contactNameBreaker: {
        maxWidth: "calc(100% - 5px)",
        textAlign: "center",
        wordWrap: "break-word",
        [theme.breakpoints.down('md')]: {
            maxWidth: "calc(100% - 100px)",
        },
    },
    contactButton: {
        width: 120,
        height: 127,
        overflow: "hidden",
        display: "flex",
        flex: "1 0 18%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start"
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
}));

const QuickChat = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchContacts = async () => {
            try {
                const { data } = await api.get("/toplisttickets");
                setContacts(data);
            } catch (err) {
                toastError(err);
            }
            setLoading(false);
        }
        fetchContacts();
    }, []);

    // Função para abrir o Popover quando um botão for clicado
    const handleButtonClick = (event, contactId) => {
        setIsPopoverOpen(true);
        setSelectedContact(contactId);
        setAnchorEl(event.currentTarget);
    };

    // Função para fechar o Popover
    const handleClosePopover = () => {
        setIsPopoverOpen(false);
        setAnchorEl(null);
        setSelectedContact(null);
    };
    const handleClose = () => {

        setContactModalOpen(false);
        setScheduleModalOpen(false);
    }
    const handleOpenScheduleModal = () => {
        setScheduleModalOpen(true);
    }

    //Função para abrir ticket ao clicar no botão com icone do whatsapp
    const handleSaveTicket = async (contactId) => {
        if (!contactId) return;
        setLoading(true);
        try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: contactId,
                userId: user?.id,
                status: "open",
            });
            history.push(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
        handleClosePopover();
        setSelectedContact(null);
        setLoading(false);
    };



    return (
        <>
            {contacts.length > 0 && !loading ?
                <div>
                    <ContactModal
                        open={contactModalOpen}
                        onClose={handleClose}
                        contactId={selectedContact?.id}
                    //initialValues={selectedContact}
                    />
                    <ScheduleModal
                        modalOpen={scheduleModalOpen}
                        onClose={handleClose}
                        contact={selectedContact}
                    />
                    <Popover
                        open={isPopoverOpen}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => handleSaveTicket(selectedContact.id)}
                        >
                            <WhatsApp />
                        </IconButton>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => setContactModalOpen(true)}
                        >
                            <Edit />
                        </IconButton>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => setScheduleModalOpen(true)}
                        >
                            <ScheduleSend />
                        </IconButton>
                    </Popover>
                    <Typography>{i18n.t("chat.featuredContacts.title")}</Typography>
                    <div className={classes.contactsWrapper}>
                        {contacts.map((contact) =>
                            <Button
                                key={contact.contactId} // Adicione uma chave única para cada botão
                                className={classes.contactButton}
                                onClick={(e) => handleButtonClick(e, contact.contact)} // Lidar com o clique no botão
                            >
                                <div style={{ display: "block" }}>
                                    <Avatar src={contact.contact.profilePicUrl} sx={{ padding: 0, width: 56, height: 56 }} />
                                </div>
                                <Typography
                                    className={classes.contactNameBreaker}
                                    noWrap
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {contact.contact.name}
                                </Typography>
                                <Typography
                                    className={classes.contactNameBreaker}
                                    noWrap
                                    component="span"
                                    variant="body3"
                                    fontSize={10}
                                    color="textSecondary"
                                >
                                    {contact.contact.company}
                                </Typography>
                                <div style={{ display: "flex", alignItems: "center", margin: "2px 0px" }}>{contact.contact.tags?.length > 0 ? <LocalOfferOutlined fontSize="8" /> : <></>}
                                    <TagsWrapper tags={contact.contact.tags} maxWidth={100} />

                                </div>
                            </Button>
                        )}
                    </div>

                </div>
                : <span>{i18n.t("chat.noTicketMessage")}</span>}
        </>
    )
}

export default QuickChat;
