import { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
    if (action.type === "LOAD_FILELISTS") {
        const fileLists = action.payload;
        const newFileLists = [];

        fileLists.forEach((fileList) => {
            const fileListIndex = state.findIndex((f) => f.id === fileList.id);
            if (fileListIndex !== -1) {
                state[fileListIndex] = fileList;
            } else {
                newFileLists.push(fileList);
            }
        });

        return [...state, ...newFileLists];
    }

    if (action.type === "UPDATE_FILELIST") {
        const fileList = action.payload;
        const fileListIndex = state.findIndex((f) => f.id === fileList.id);

        if (fileListIndex !== -1) {
            state[fileListIndex] = fileList;
            return [...state];
        } else {
            return [fileList, ...state];
        }
    }

    if (action.type === "DELETE_FILELIST") {
        const fileListId = action.payload;

        const fileListIndex = state.findIndex((f) => f.id === fileListId);
        if (fileListIndex !== -1) {
            state.splice(fileListIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useFileLists = ({ searchParam, pageNumber, showAll = false }) => {
    const [fileLists, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
            const fetchFileLists = async () => {
                try {
                    const { data } = await api.get("/filelists/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_FILELISTS", payload: data.fileLists });
                    setHasMore(data.hasMore);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchFileLists();
            setLoading(false);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const socket = openSocket();

        socket.on("fileList", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_FILELIST", payload: data.fileList });

            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_FILELIST", payload: +data.fileListId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return { fileLists, hasMore, loading };
};

export default useFileLists;