import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { i18n } from "../../translate/i18n";
import Switch from "@mui/material/Switch";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { IconButton, InputAdornment } from "@mui/material";
import { Colorize, Help } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import ConfirmationModal from "../ConfirmationModal";
import ChatbotOptions from "../ChatbotOptions";
import useQueues from "../../hooks/useQueues";
import useUsers from "../../hooks/useUsers";
import useFileList from "../../hooks/useFileList";
import AdvancedTextField from "../AdvancedTextField";
import useIntegrations from "../../hooks/useIntegrations";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	greetingMessage: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	custom: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
		//paddingBottom: "60px !important",
		"& textarea": {
			marginBottom: "30px !important",
		}
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	chatbotOptions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "left",
	},
	iconButton: {
		color: theme.palette.text.secondary
	},
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
	chatbots: Yup.array()
		.of(
			Yup.object().shape({
				name: Yup.string().min(4, "too short").required("Required"),
			})
		)
		.required("Must have friends"),
});

const QueueModal = ({ open, onClose, queueId, onEdit }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		color: "",
		greetingMessage: "",
		awayMessage: "",
		chatbots: [],
	};

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const [queues, setQueues] = useState([]);
	//const [users, setUsers] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchParam, setSearchParam] = useState("");
	const { users, loading: loadingUsers } = useUsers({ searchParam, pageNumber });
	const { findAll } = useQueues();
	const { fileLists, loading } = useFileList({ showAll: true });
	const { integrations } = useIntegrations({ showAll: true });
	const greetingRef = useRef();
	const [activeStep, setActiveStep] = React.useState(null);
	const [selectedQueue, setSelectedQueue] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [isStepContent, setIsStepContent] = React.useState(true);
	const [isNameEdit, setIsNamedEdit] = React.useState(null);
	const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
	const [greetingsMedias, setGreetingMedias] = useState([]);
	const [awayMessageMedias, setAwayMessageMedias] = useState([]);
	const textTemplates = {
		greeting: "Bom dia/Boa tarde/Boa noite",
		contactName: "Nome do contato",
		contactNumber: "Número do contato",
		contactEmail: "Email do contato",
		ticketNumber: "Número do ticket atual"
	}


	useEffect(() => {
		(async () => {
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				setQueue((prevState) => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setQueue({
				name: "",
				color: "",
				greetingMessage: "",
				awayMessage: "",
				chatbots: [],
			});
		};
	}, [queueId, open]);

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAll();
			//setAllQueues(list);
			setQueues(list);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		if (activeStep === isNameEdit) {
			setIsStepContent(false);
		} else {
			setIsStepContent(true);
		}
	}, [isNameEdit, activeStep]);

	const handleClose = () => {
		onClose();
		setIsNamedEdit(null);
		setActiveStep(null);
		setGreetingMessageEdit(null);
	};

	const handleCloseConfirmationModal = () => {
		setConfirmModalOpen(false);
		setSelectedQueue(null);
	};

	const handleDeleteQueue = async (optionsId) => {
		try {
			await api.delete(`/chatbot/${optionsId}`);
			const { data } = await api.get(`/queue/${queueId}`);
			setQueue(initialState);
			setQueue(data);
			setIsNamedEdit(null);
			setGreetingMessageEdit(null);
			toast.success(i18n.t("chatbots.chatbotDeleted"));
		} catch (err) {
			toastError(err);
		}
	};

	const handleSaveQueue = async (values) => {
		const whatsappData = new FormData();//{ ...values, queueIds: selectedQueueIds, connectionType: connType };
		whatsappData.append("name", values.name);
		whatsappData.append("color", values.color);
		whatsappData.append("greetingMessage", values.greetingMessage);
		whatsappData.append("farewellMessage", values.farewellMessage);
		whatsappData.append("awayMessage", values.awayMessage);
		if (greetingsMedias) {
			greetingsMedias.forEach((media) => {
				whatsappData.append("greetingMedia", media);
			});
		}
		if (awayMessageMedias) {
			awayMessageMedias.forEach((media) => {
				whatsappData.append("awayMessageMedia", media);
			});
		}
		try {
			if (queue.id) {
				await api.put(`/queue/${queue.id}`, whatsappData);
			} else {
				await api.post("/queue", values);
			}
			toast.success(i18n.t("queues.queueSaved"));
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleSaveBot = async (values) => {

		try {
			if (queue.id) {
				const { data } = await api.put(`/queue/${queue.id}`, values);
				if (data && data.chatbots && data.chatbots.length) {
					//onEdit(data);
					setQueue(data);
				}
			} else {
				const { data } = await api.post("/queue", values);
				if (data.chatbots && data.chatbots.length) {
					setQueue(data);
					//onEdit(data);
				}
			}

			setIsNamedEdit(null)
			setGreetingMessageEdit(null)
			toast.success(i18n.t("chatbots.chatbotSaved"));

		} catch (err) {
			toastError(err?.message || "Failed to save chatbot")
		}
	};


	return (
		<div className={classes.root}>
			<ConfirmationModal
				title={
					selectedQueue &&
					`${i18n.t("chatbots.confirmationModal.deleteTitle", { option: selectedQueue.name })}`
				}
				open={confirmModalOpen}
				onClose={handleCloseConfirmationModal}
				onConfirm={() => handleDeleteQueue(selectedQueue.id)}
			>
				{i18n.t("chatbots.confirmationModal.deleteMessage")}
			</ConfirmationModal>
			<Dialog
				maxWidth="md"
				fullWidth
				open={open}
				onClose={handleClose}
				scroll="paper"
			>
				<DialogTitle
					color="textPrimary"
				>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					validateOnChange={false}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ handleChange, touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<div style={{ maxHeight: "55vh" }}>
									<div className={classes.multFieldLine}>

										<Field
											as={TextField}
											label={i18n.t("queueModal.form.name")}
											autoFocus
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.color")}
											name="color"
											id="color"
											onClick={() => {
												setColorPickerModalOpen(true);
												//greetingRef.current.focus();
											}}
											error={touched.color && Boolean(errors.color)}
											helperText={touched.color && errors.color}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<div
															style={{ backgroundColor: values.color }}
															className={classes.colorAdorment}
														></div>
													</InputAdornment>
												),
												endAdornment: (
													<IconButton
														size="small"
														color="default"
														onClick={() => setColorPickerModalOpen(true)}
														className={classes.iconButton}
													>
														<Colorize />
													</IconButton>
												),
											}}
											variant="outlined"
											margin="dense"
										/>
										<ColorPicker
											open={colorPickerModalOpen}
											handleClose={() => setColorPickerModalOpen(false)}
											onChange={(color) => {
												values.color = color;
												setQueue(() => {
													return { ...values, color };
												});
											}}
										/>
									</div>
									<Field
										as={AdvancedTextField}
										selectFiles={true}
										selectTemplates={true}
										templates={textTemplates}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										fullWidth
										margin="dense"
										onChange={(value, medias) => {
											values.greetingMessage = value
											setGreetingMedias(medias);
										}}
										rows={4}
										id="greetingMessage"
										name="greetingMessage"
										uploadName="greetingMediaUrl"
										uploadValue={values.greetingMediaUrl}
										className={classes.textField}
										variant="outlined"
									/>
									<Field
										as={AdvancedTextField}
										selectFiles={true}
										selectTemplates={true}
										templates={textTemplates}
										label={i18n.t("whatsappModal.form.awayMessage")}
										type="message"
										multiline
										fullWidth
										margin="dense"
										onChange={(value, medias) => {
											values.awayMessage = value
											setAwayMessageMedias(medias);
										}}
										rows={2}
										id="awayMessage"
										name="awayMessage"
										uploadName="awayMessageMediaUrl"
										uploadValue={values.awayMessageMediaUrl}
										className={classes.textField}
										variant="outlined"
									/>


									<Typography variant="subtitle1">
										{i18n.t("chatbots.title")}
										<Tooltip arrow interactive title={
											<React.Fragment>
												<Typography variant="body2">{i18n.t("chatbots.tips.mainTip.title")}</Typography>
												<p>{i18n.t("chatbots.tips.mainTip.message")}</p>
											</React.Fragment>}
										>
											<IconButton
												size="small"
												className={classes.iconButton}
											>
												<Help />
											</IconButton>
										</Tooltip>
									</Typography>

									<div>
										<FieldArray name="chatbots">
											{({ push, remove }) => (
												<>
													<Stepper
														nonLinear
														activeStep={activeStep}
														orientation="vertical"
													>
														{values.chatbots &&
															values.chatbots.length > 0 &&
															values.chatbots.map((info, index) => (
																<Step
																	key={`${info.id ? info.id : index}-chatbots`}
																	onClick={() => setActiveStep(index)}
																>
																	<StepLabel key={`${info.id}-chatbots`}>
																		{isNameEdit !== index &&
																			queue.chatbots[index]?.name ? (
																			<div
																				className={classes.greetingMessage}
																				variant="body1"
																			>
																				<div>
																					<div
																						className={classes.greetingMessage}
																						variant="body1"
																					>
																						<Typography
																							color="textSecondary"
																							variant="body1"
																						>
																							{i18n.t("Opção") + ":"}
																						</Typography>
																						{values.chatbots[index].name}
																					</div>
																					<div
																						className={classes.greetingMessage}
																						variant="body1"
																					>
																						<Typography
																							color="textSecondary"
																							variant="body1"
																						>
																							{i18n.t("Tipo") + ":"}
																						</Typography>
																						{values.chatbots[index].type === "text" && i18n.t("Texto com opções")}
																						{values.chatbots[index].type === "queue" && i18n.t("Redirecionar para fila")}
																						{values.chatbots[index].type === "attendant" && i18n.t("Redirecionar para atendente")}
																						{values.chatbots[index].type === "fileList" && i18n.t("Lista de arquivos")}
																						{values.chatbots[index].type === "integration" && i18n.t("Integração")}
																					</div>

																				</div>


																				<IconButton
																					size="small"
																					onClick={() => {
																						setIsNamedEdit(index);
																						setIsStepContent(false);
																					}}
																					className={classes.iconButton}
																				>
																					<EditIcon />
																				</IconButton>

																				<IconButton
																					onClick={() => {
																						setSelectedQueue(info);
																						setConfirmModalOpen(true);
																					}}
																					size="small"
																					color="secondary"
																				>
																					<DeleteOutline />
																				</IconButton>
																			</div>
																		) : (
																			<div className={classes.chatbotOptions}>
																				<Field
																					as={TextField}
																					label={i18n.t("Nome da opção")}
																					name={`chatbots[${index}].name`}
																					variant="outlined"
																					margin="dense"
																					color="primary"
																					disabled={isSubmitting}
																					autoFocus
																					error={
																						touched?.chatbots?.[index]?.name &&
																						Boolean(
																							errors.chatbots?.[index]?.name
																						)
																					}
																					className={classes.textField}
																				/>
																				<FormControl style={{ minWidth: "150px" }} className={classes.settingOption}>
																					<InputLabel id="option-selection-input-label">
																						{"Selecione o tipo da opção"}
																					</InputLabel>
																					<Field
																						as={Select}
																						margin="dense"
																						label="Selecione o tipo da opção"
																						labelId="option-selection-input-label"
																						name={`chatbots[${index}].type`}
																						error={
																							touched?.chatbots?.[index]?.type &&
																							Boolean(
																								errors.chatbots?.[index]?.type
																							)
																						}
																						variant="outlined"
																						className={classes.settingOption}
																					>
																						<MenuItem value="text">Texto com opções</MenuItem>
																						<MenuItem value="queue">Fila</MenuItem>
																						<MenuItem value="attendant">Atendente</MenuItem>
																						<MenuItem value="fileList">Lista de arquivos</MenuItem>
																						<MenuItem value="integration">Integração</MenuItem>
																					</Field>
																				</FormControl>

																				<IconButton
																					size="small"
																					onClick={() =>
																						values.chatbots[index].name
																							? handleSaveBot(values)
																							: null
																					}
																					color="primary"
																					disabled={isSubmitting}
																				>
																					<SaveIcon />
																				</IconButton>

																				<IconButton
																					size="small"
																					onClick={() => remove(index)}
																					disabled={isSubmitting}
																					color="secondary"
																				>
																					<DeleteOutline />
																				</IconButton>
																			</div>
																		)}
																	</StepLabel>

																	{isStepContent && queue.chatbots[index] && (
																		<StepContent>
																			<>
																				{isGreetingMessageEdit !== index ? (
																					<div
																						className={classes.greetingMessage}
																					>
																						<div>
																							{queue.chatbots[index].type !== "queue" || queue.chatbots[index]?.type !== "integration" &&
																								<div
																									className={classes.greetingMessage}
																								>
																									<Typography
																										color="textSecondary"
																										variant="body1"
																									>
																										{i18n.t("chatbots.message") + ":"}
																									</Typography>
																									{queue.chatbots[index].greetingMessage}
																								</div>
																							}
																							{queue.chatbots[index].type !== "text" &&
																								<div
																									className={classes.greetingMessage}
																								>
																									<Typography
																										color="textSecondary"
																										variant="body1"
																									>
																										{(() => {
																											switch (values.chatbots[index].type) {
																												case "queue":
																													return "Fila:";
																												case "attendant":
																													return "Atendente:";
																												case "fileList":
																													return "Lista de arquivos:";
																												case "integration":
																													return "Integração:";
																											}
																										})()}
																									</Typography>
																									{queue.chatbots[index].type === "queue" &&
																										queues.find((q) => q.id === queue.chatbots?.[index]?.toQueueId)?.name
																									}
																									{queue.chatbots[index].type === "attendant" &&
																										users.find((user) => user.id === queue.chatbots?.[index]?.toUserId)?.name
																									}
																									{queue.chatbots[index].type === "fileList" &&
																										fileLists.find((fileList) => fileList.id === queue.chatbots?.[index]?.fileListId)?.name
																									}
																									{queue.chatbots[index].type === "integration" &&
																										integrations.find((integration) => integration.id === queue.chatbots?.[index]?.integrationId)?.name
																									}
																								</div>
																							}
																						</div>
																						{!queue.chatbots[index]
																							?.greetingMessage && (
																								<Tooltip arrow interactive title={
																									<React.Fragment>
																										<Typography variant="body2">{i18n.t("chatbots.tips.optionTip.title")}</Typography>
																										<p>{i18n.t("chatbots.tips.optionTip.message")}</p>
																									</React.Fragment>}
																								>
																									<IconButton
																										size="small"
																										className={classes.iconButton}
																									>
																										<Help />
																									</IconButton>
																								</Tooltip>
																							)}

																						<IconButton
																							size="small"
																							onClick={() =>
																								setGreetingMessageEdit(index)
																							}
																							className={classes.iconButton}
																						>
																							<EditIcon />
																						</IconButton>

																					</div>

																				) : (
																					<div
																						className={classes.greetingMessage}
																					>
																						<div>
																							<div
																								className={classes.greetingMessage}
																							>
																								{(values.chatbots[index]?.type !== "queue" || values.chatbots[index]?.type !== "integration") &&
																									<Field
																										as={TextField}
																										label={i18n.t("chatbots.message")}
																										name={`chatbots[${index}].greetingMessage`}
																										variant="outlined"
																										margin="dense"
																										multiline
																										rows={4}
																										error={
																											touched.greetingMessage &&
																											Boolean(errors.greetingMessage)
																										}
																										helperText={
																											touched.greetingMessage &&
																											errors.greetingMessage
																										}
																										className={classes.textField}
																									/>
																								}
																							</div>
																							<div
																								className={classes.greetingMessage}
																							>

																								{values.chatbots[index]?.type !== "text" &&
																									<FormControl style={{ minWidth: "150px" }} className={classes.settingOption}>
																										<InputLabel id="chattype-selection-input-label">
																											{values.chatbots[index]?.type === "queue" && i18n.t("Selecione uma fila:")}
																											{values.chatbots[index]?.type === "attendant" && i18n.t("Selecione um atendente:")}
																											{values.chatbots[index]?.type === "fileList" && i18n.t("Selecione uma lista de arquivos:")}
																											{values.chatbots[index]?.type === "integration" && i18n.t("Selecione uma integração:")}
																										</InputLabel>
																										<Field
																											as={Select}
																											label={<>
																												{values.chatbots[index]?.type === "queue" && i18n.t("Selecione uma fila:")}
																												{values.chatbots[index]?.type === "attendant" && i18n.t("Selecione um atendente:")}
																												{values.chatbots[index]?.type === "fileList" && i18n.t("Selecione uma lista de arquivos:")}
																												{values.chatbots[index]?.type === "integration" && i18n.t("Selecione uma integração:")}
																											</>}
																											labelId="chattype-selection-input-label"
																											error={
																												touched?.chatbots?.[index]?.toUserId &&
																												Boolean(
																													errors.chatbots?.[index]?.toUserId
																												)
																											}
																											variant="outlined"
																											className={classes.settingOption}
																											name={(() => {
																												switch (values.chatbots[index].type) {
																													case "queue":
																														return `chatbots[${index}].toQueueId`;

																													case "attendant":
																														return `chatbots[${index}].toUserId`;
																													case "fileList":
																														return `chatbots[${index}].fileListId`;
																													case "integration":
																														return `chatbots[${index}].integrationId`;
																												}
																											})()}

																										>
																											{values.chatbots[index]?.type == "queue" && queues && queues.map((queue) =>
																												<MenuItem value={queue.id}>{queue.name}</MenuItem>
																											)}
																											{values.chatbots[index]?.type === "attendant" && users && users.map((user) =>
																												<MenuItem value={user.id}>{user.name}</MenuItem>
																											)}
																											{values.chatbots[index]?.type === "fileList" && fileLists && fileLists.map((fileList) =>
																												<MenuItem value={fileList.id}>{fileList.name}</MenuItem>
																											)}
																											{values.chatbots[index]?.type === "integration" && integrations && integrations.map((integration) =>
																												<MenuItem value={integration.id}>{integration.name}</MenuItem>
																											)}
																										</Field>
																									</FormControl>
																								}
																								<IconButton
																									size="small"
																									//variant="contained"
																									onClick={() =>
																										handleSaveBot(values)
																									}
																									color="primary"
																									disabled={isSubmitting}
																								>

																									<SaveIcon />
																								</IconButton>
																							</div>

																						</div>

																					</div>
																				)}
																				<Divider />

																				<ChatbotOptions chatBotId={info.id} />
																			</>
																		</StepContent>
																	)}
																</Step>
															))}

														<Step>
															<StepLabel>
																<Button
																	style={{ flex: 1, marginTop: 8 }}
																	color="primary"
																	onClick={() => push({ name: "", value: "" })}
																>

																	{`+ ${i18n.t("chatbots.addButton")}`}
																</Button>
															</StepLabel>
														</Step>
													</Stepper>
												</>
											)}
										</FieldArray>
									</div>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default QueueModal;
