import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";

function connectToSocket() {
    try{
        return openSocket(getBackendUrl());
    }
    catch(err){
        console.log(err?.message|| err);
    }
}

export default connectToSocket;