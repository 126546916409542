import React from "react";

import { Avatar, CardHeader, Chip, Tooltip, Typography } from "@mui/material";

import { i18n } from "../../translate/i18n";
import { BookmarkBorder, BookmarkBorderOutlined, LocalOfferOutlined } from "@mui/icons-material";
import TagsWrapper from "../TagsWrapper";
import { useTheme } from "@emotion/react";

const TicketInfo = ({ contact, ticket, onClick }) => {
	const theme = useTheme();

	return (<>
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer", padding: 0 }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={
				<Avatar src={contact.profilePicUrl} />
			}
			title={
				<>{ticket.contact?.company ?
					<>
						{ticket.contact?.name}
						<Typography
							noWrap
							component="span"
							variant="body2"
							color="textSecondary"
						>
							{` (${ticket.contact.company})`}
						</Typography>
					</>
					: ticket.contact?.name}
					<Tooltip title={ticket.queue?.name || "Sem fila"} arrow ><span style={{ color: ticket.queue?.color || theme.palette.text.secondary, fontStyle: "italic" }}>{` #${ticket.id}`}</span></Tooltip>
				</>
			}
			subheader={
				<>
					{ticket.user &&
						<>{`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`}</>
					}
					<div style={{ display: "flex", alignItems: "center", margin: "2px 0px" }}>{contact.tags?.length > 0 ? <LocalOfferOutlined fontSize="8" /> : <></>}
						<TagsWrapper tags={contact.tags} maxWidth={300} />
					</div>
					<div style={{ display: "flex", alignItems: "center", margin: "2px 0px" }}>{ticket.categories?.length > 0 ? <BookmarkBorderOutlined fontSize="8" /> : <></>}
						<TagsWrapper tags={ticket.categories} maxWidth={300} />
					</div>
				</>
			}
		/>

	</>
	);
};

export default TicketInfo;
