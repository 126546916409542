import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";

import App from "./App";
import registerServiceWorker from "./services/registerServiceWorker";
import { getAppname } from "./config";
import generateManifestJson from "./services/generateManifestJson";

registerServiceWorker();
generateManifestJson();
document.getElementById("dynamic-title").textContent = getAppname();
ReactDOM.render(
		<App />,
	document.getElementById("root")
);

// ReactDOM.render(
// 	<React.StrictMode>
// 		<CssBaseline>
// 			<App />
// 		</CssBaseline>,
//   </React.StrictMode>

// 	document.getElementById("root")
// );
