//import whatsBackground from "../../src/assets/wa-background-hires.png";
import whatsBackgroundDark from "../../src/assets/wa-background-hires-dark.png";
import { alpha, createTheme } from "@mui/material";

const adwDarkTheme = createTheme(
    {
        typography: {

            fontFamily: 'Cantarell, Segoe UI, Arial',
            fontSize: 14,

            h1: {
                fontSize: '1.5rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h2: {
                fontSize: '1.4rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h3: {
                fontSize: '1.25rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h4: {
                fontSize: '1.1rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h5: {
                fontSize: '1rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h6: {
                fontSize: '.875rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            body1: {
                fontSize: '.875rem',
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {

                    "::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        padding: "10px"
                    },

                    "::-webkit-scrollbar-track": {
                        background: "transparent",
                    },

                    "::-webkit-scrollbar-thumb": {
                        background: "#8696a0",
                        borderRadius: "50px",
                        border: "1px solid transparent",
                        backgroundClip: "padding-box",
                    },

                    "::-webkit-scrollbar-thumb:hover": {
                        background: "#5d6469",
                        border: 0
                    },
                    "*": {
                        fontFamily: '"NoirPro", Arial',
                        fontWeight: 300
                    },
                    "strong": {
                        fontWeight: 500
                    },
                },
            },
            /* MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: 'inherit',
                        background: '#FFFFFF',
                        '&:hover': {
                            background: '#FFFFFF',
                        }
                    }
                },
            }, */
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '&.mainListItems': {
                            padding: "2px 17px",
                            margin: "1px 4px",
                            //marginRight: "5px",
                            borderRadius: "6px",
                            "&:hover": {
                            },
                            '&.Mui-selected': {
                                backgroundColor: "#454545",
                            },
                        }
                    },
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "#8696A01F",
                    },
                    root: {
                        '& .MuiSvgIcon-root': {
                            color: "#A7B2B9",
                        },
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small', // Define o tamanho small como padrão
                },
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small', // Define o tamanho small como padrão
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0
                },
                styleOverrides: {
                    rounded: {
                        borderRadius: 12,
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        minHeight: 'auto',
                        margin: "0px 4px",
                        //marginRight: "5px",
                        borderRadius: "6px",
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    rounded: {
                        borderRadius: 12,
                        boxShadow: `0 0.5rem 1.25rem ${alpha('#7352C7', .175)}`
                    },
                },
            },

            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: 24
                    },
                },
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        padding: '18px 24px'
                    },
                    title: {
                        //fontSize: '1.1rem',
                        marginBottom: 0
                    },
                    subheader: {
                        margin: '4px 0 0'
                    },
                    action: {
                        margin: 0
                    }
                }
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: '8px 24px'
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    sizeSmall: {
                        height: 22,
                        fontSize: 12,
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                },
                styleOverrides: {
                    root: {
                        fontWeight: 400,
                        letterSpacing: 1
                    },
                    
                    sizeSmall: {
                        fontSize: '12px'
                    }
                }
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        borderRadius: 8
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        fontSize: 18
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: '16px 24px'
                    }
                }
            },
            MuiAvatarGroup: {
                styleOverrides: {
                    avatar: {
                        backgroundColor: '#757575',
                        fontSize: 16
                    }
                }
            }
        },
        palette: {
            primary: {
                main: "#3584e4",
                light: "#4990e7",
                dark: '#2d5484',
                contrastText: '#FFF'
            },
            secondary: {
                main: "#f44336",
                light: '#ff867e',
                dark: '#c50b28',
                contrastText: '#FFF'
            },
            error: {
                main: '#E73145',
                light: '#FF6A70',
                dark: '#AD001E',
                contrastText: '#FFF'
            },
            warning: {
                main: '#F39711',
                light: '#FFC84C',
                dark: '#BB6900',
                contrastText: '#FFF'
            },
            info: {
                main: '#2EB5C9',
                light: '#6FE7FC',
                dark: '#008598',
                contrastText: '#FFF'
            },
            success: {
                main: '#3BD2A2',
                light: '#78FFD3',
                dark: '#00A073',
                contrastText: '#FFF'
            },
            text: {
                primary: '#ffffff',//Texto
                secondary: '#c1c1c1',
                disabled: '#8696a0',
            },
            shadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            divider: alpha('#FFFFFF', 0.1),
            background: {
                paper: '#303030',
                default: '#1e1e1e',
                header: '#303030',
                list: '#1E1E1E',
                messagesList: {
                    image: `url(${whatsBackgroundDark})`,
                    dark: "#181818",
                }
            },
            action: {
                active: '#454545',
                hover: '#393939',
                disabled: '#797979',
                //disabledBackground: '#8595A6'
            },
        },
    }
);
export default adwDarkTheme;