import React, { useState, useEffect, useRef } from 'react';
import MarkdownWrapper from '../MarkdownWrapper';

const TextResumer = ({ children, limit = 767 }) => {
    const [readMore, setReadMore] = useState(false);
    const [textOverflow, setTextOverflow] = useState(false);
    const textRef = useRef(null);


    useEffect(() => {
        if (textRef.current) {
            setTextOverflow(textRef.current.textContent.length > limit);
        }
    }, [children, limit]);

    const handleReadMore = () => {
        setReadMore(!readMore);
    };

    return (<>
        <span
            ref={textRef}
        >
            <MarkdownWrapper>
                {readMore || !textOverflow ? children : `${children.slice(0, limit)}...`}
            </MarkdownWrapper>
        </span>
        {!readMore && textOverflow && (<>
            <span style={{ color: "#027eb5", padding: "0 2px", cursor: "pointer" }} onClick={handleReadMore}>
                Leia mais
            </span>
        </>)}
    </>);
};

export default TextResumer;
