import React from "react";
import { Avatar, Badge, Chip, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { i18n } from "../../translate/i18n";
import { format, isSameDay, parseISO } from "date-fns";
import { Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import Skeleton from "@mui/lab/Skeleton/Skeleton";
const useStyles = makeStyles(theme => ({
    ticket: {
        position: "relative",
        height: '65px'
    },

    pendingTicket: {
        cursor: "pointer",
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },

    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    contactNameWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },

    closedBadge: {
        alignSelf: "center",
        justifySelf: "flex-end",
        marginRight: 32,
        marginLeft: "auto",
    },

    contactLastMessage: {
        paddingRight: 20,
    },

    newMessagesCount: {
        alignSelf: "center",
        marginRight: 8,
        marginLeft: "auto",
    },

    badgeStyle: {
        color: "white",
        right: -9,
        top: 3,
        padding: '0 5px',
        backgroundColor: green[500],
    },

    acceptButton: {
        position: "absolute",
        left: "50%",
    },

    ticketQueueColor: {
        flex: "none",
        width: "8px",
        height: "100%",
        position: "absolute",
        top: "0%",
        left: "0%",
    },

    userTag: {
        position: "absolute",
        marginRight: 5,
        right: 5,
        bottom: 5,
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary + "1a",
        cursor: "pointer",
    },
    divider: {
        backgroundColor: theme.palette.divider,
    },
    chipContainer: {
        whiteSpace: 'nowrap',
        maxWidth: '80%',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '50px',
    }
}));

const HiddenTicket = ({ ticket }) => {
    const classes = useStyles();

    const connectionChip = (ticket) => {
        let style = { color: "#369b4b", fontSize: 18, marginLeft: 4 }
        const renderIcon = () => {
            switch (ticket.whatsapp?.connectionType) {

                case 'bwhatsapp': case 'whatsapp':
                    style = { ...style, color: "#369b4b" }
                    return (
                        <WhatsApp style={style} />
                    )
                case "instagram":
                    style = { ...style, color: "#833AB4" }
                    return (
                        <Instagram style={style} />
                    )
                case "facebook":
                    style = { ...style, color: "#2576D2" }
                    return (
                        <Facebook style={style} />
                    )
            }
        }

        return (
            <Tooltip
                title={i18n.t("ticketsList.connectionTitle")}
                placement="right"
                arrow
            >
                <Chip
                    className={classes.userTag}
                    variant="outlined"
                    size='small'

                    icon={renderIcon()}
                    label={ticket.whatsapp?.name}
                >
                </Chip>
            </Tooltip>
        )
    }

    return (<>
        <ListItemAvatar >
            <Avatar />
        </ListItemAvatar>
        <ListItemText
            disableTypography
            primary={
                <span className={classes.contactNameWrapper}>
                    {/* <Typography
                        noWrap
                        component="span"
                        variant="body2"
                        color="textPrimary"
                    >
                       {`Ticket: #${ticket?.id}`}
                    </Typography> */}
                    <Badge
                        className={classes.newMessagesCount}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        badgeContent={ticket.unreadMessages}
                        classes={{
                            badge: classes.badgeStyle,
                        }}
                    >
                        {ticket.lastMessage && (
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                            >
                                {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                                    <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                                ) : (
                                    <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                                )}
                            </Typography>
                        )}
                    </Badge>

                    {ticket?.whatsappId && connectionChip(ticket)}



                </span>
            }

            secondary={<>
                <span className={classes.contactNameWrapper}>
                    <Typography
                        className={classes.contactLastMessage}
                        noWrap
                        component="span"
                        variant="body2"
                        color="textSecondary"
                    >
                        {ticket.lastMessage ? (
                            <Skeleton animation="wave" height={20} width={90} />
                        ) : (
                            <br />
                        )}
                    </Typography>
                </span>

            </>
            }
        /></>
    )
}
export default HiddenTicket;