import React, { useEffect, useReducer, useState } from "react";
import {
    Avatar,
    Button,
    Checkbox,
    Chip,
    colors,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import TableRowSkeleton from "../TableRowSkeleton";
import AvatarTooltip from "../AvatarTooltip";
import { Info } from "@mui/icons-material";
import useTags from "../../hooks/useTags";
import TagsWrapper from "../TagsWrapper";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",

    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
    chipContainer: {
        whiteSpace: 'nowrap',
        maxWidth: '170px',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '50px',
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const TagContactsModal = ({ open, onClose, tagId }) => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    const [listedContacts, setContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingContacts, setLoadingContacts] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const { tags } = useTags({ showAll: true });
    const [tagFilter, setTagFilter] = useState('');

    const [tag, setTag] = useState('');

    useEffect(() => {
        (async () => {
            setLoading(true)
            if (!tagId) return;
            try {
                const { data } = await api.get(`/tags/${tagId}`);
                setSelectedContacts(data.contacts);
                setContacts(data.contacts);
                setTag(data);
                setLoading(false)
            } catch (err) {
                toastError(err);
                setLoading(false)

            }
        })();

        return () => {

        };
    }, [tagId, open]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, open, tagFilter]);

    useEffect(() => {
        setLoadingContacts(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/contacts/", {
                        params: { searchParam, pageNumber, tagFilter },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    setHasMore(data.hasMore);
                    setLoadingContacts(false);
                } catch (err) {
                    toastError(err);
                    setLoadingContacts(false);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, tagFilter, open]);

    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    };

    const handleScroll = (e) => {
        if (!hasMore || loadingContacts) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    }

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleChange = (e) => {
        let value = +e.target.value;
        let ContactsList = contacts.filter((contact) => contact.id === value)
        let checked = e.target.checked;
        if (checked === true) {
            setSelectedContacts([...selectedContacts, ContactsList[0]])
        } else if (checked === false) {
            setSelectedContacts((current) => current.filter((contact) => contact.id !== value));
        }
    }

    const handleClose = () => {
        setSelectedContacts([])
        setLoading(false)
        onClose();
    }

    const unselectAll = () => {
        setSelectedContacts([]);
    }
    const handleChangeFilter = (event) => {
        setTagFilter(event.target.value);
    };

    const handleAddContacts = async () => {
        setLoading(true);
        let values = {...tags, contacts: selectedContacts}
        try {
            if (tagId) {
                await api.put(`/tags/${tagId}`, values);
            } else {
                await api.post("/tags", values);
            }
            toast.success(i18n.t('Tag adicionada aos contatos selecionados'));
        } catch (err) {
            toastError(err);
        }
        handleClose()
    }

    const Sidebar = () => {
        return (
            <Grid item xs={5}>
                <Paper style={{ padding: '20px' }} elevation={0} variant='outlined'>
                    <Typography variant="body2" gutterBottom>
                        {`${i18n.t('tags.contactsModal.notices.selecteds')} ${selectedContacts.length}`}
                    </Typography>
                    <div style={{ height: 300, overflowY: "scroll" }}>
                        <FormGroup row>
                            <Table size="small">
                                <TableHead>
                                    <TableRow className={classes.tableCustomRow} >
                                        <TableCell align="center" padding="checkbox" />
                                        <TableCell align="center" padding="checkbox" />
                                        <TableCell align="left">{i18n.t("campaigns.table.name")}</TableCell>
                                        <TableCell align="center">
                                            {i18n.t("campaigns.table.whatsapp")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedContacts?.length > 0 &&
                                        selectedContacts.map(contact => (
                                            <TableRow className={classes.tableCustomRow}>
                                                <TableCell align="center" padding="checkbox">
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            size="small"
                                                            color="primary"
                                                            value={contact.id}
                                                            checked={selectedContacts.some(current => current.id === contact.id)}
                                                            onChange={handleChange}
                                                            name={contact.number}

                                                        />} />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {contact.extraInfo?.length > 0 ?
                                                        <AvatarTooltip
                                                            arrow
                                                            icon={<Info />}
                                                            placement="right"
                                                            title={
                                                                <>
                                                                    {contact.extraInfo?.length > 0 && contact.extraInfo.map((info) =>
                                                                        <Typography>
                                                                            {`${info.name}: ${info.value}`}
                                                                        </Typography>
                                                                    )}
                                                                </>
                                                            }
                                                        >
                                                            <Avatar src={contact.profilePicUrl} />
                                                        </AvatarTooltip>
                                                        : <Avatar src={contact.profilePicUrl} />
                                                    }

                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        noWrap
                                                        style={{ maxWidth: 150, fontSize: 14 }}
                                                    >
                                                        {contact.name}
                                                        {contact.company &&
                                                            <div>
                                                                <Typography
                                                                    noWrap
                                                                    style={{ marginTop: "-5px", maxWidth: 150, fontSize: 12 }}
                                                                    color="textSecondary"
                                                                >
                                                                    {`${contact.company}`}
                                                                </Typography>
                                                            </div>
                                                        }
                                                        <div className={classes.chipContainer}>
                                                            <TagsWrapper tags={contact.tags} maxWidth={120} />
                                                        </div>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">{contact.number} </TableCell>
                                            </TableRow>
                                        ))}{loading && <TableRowSkeleton checkboxes={2} avatar={1} columns={2} />}
                                </TableBody>
                            </Table>
                        </FormGroup>
                    </div>
                    <Button onClick={unselectAll}>{i18n.t('tags.contactsModal.buttons.unselectAll')}</Button>
                </Paper>

            </Grid>
        )
    }

    return (
        <div className={classes.root}>
            <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose} scroll="paper">
                <DialogTitle
                    color="textPrimary"
                >
                    {i18n.t("tags.contactsModal.title", { tagName: tag.name })}
                </DialogTitle>
                <DialogContent dividers>
                    <>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={7}>
                                <div className={classes.root}>
                                    <TextField
                                        placeholder={i18n.t("contacts.searchPlaceholder")}
                                        type="search"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={searchParam}
                                        onChange={handleSearch}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon style={{ color: "gray" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {tags?.length > 0 && <FormControl variant="outlined" margin='dense' className={classes.formControl}>
                                        <InputLabel>{i18n.t("tags.title")}</InputLabel>
                                        <Select
                                            value={tagFilter}
                                            onChange={handleChangeFilter}
                                            label={i18n.t("tags.title")}
                                        >
                                            <MenuItem value={''}>&nbsp;</MenuItem>
                                            {tags.map(tag =>
                                                <MenuItem value={tag.name}>{tag.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>}
                                </div>
                                <div style={{ height: 330, overflowY: "scroll" }} onScroll={handleScroll}>
                                    <FormGroup row>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow className={classes.tableCustomRow} >
                                                    <TableCell padding="checkbox" />
                                                    <TableCell padding="checkbox" />
                                                    <TableCell align="center">{i18n.t("tags.table.name")}</TableCell>
                                                    <TableCell align="center">
                                                        {i18n.t("campaigns.table.whatsapp")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {contacts?.length > 0 &&
                                                    contacts.map(contact => (


                                                        <TableRow className={classes.tableCustomRow} key={contact.id}>
                                                            <TableCell align="center">
                                                                <FormControlLabel
                                                                    control={<Checkbox
                                                                        size="small"
                                                                        color="primary"
                                                                        value={contact.id}
                                                                        checked={selectedContacts.some(current => current.id === contact.id)}
                                                                        onChange={handleChange}
                                                                        name={contact.number}
                                                                    />} />
                                                            </TableCell>
                                                            <TableCell style={{ paddingRight: 0 }}>
                                                                {contact.extraInfo?.length > 0 ?
                                                                    <AvatarTooltip
                                                                        arrow
                                                                        icon={<Info />}
                                                                        placement="right"
                                                                        title={
                                                                            <>
                                                                                {contact.extraInfo?.length > 0 && contact.extraInfo.map((info) =>
                                                                                    <Typography>
                                                                                        {`${info.name}: ${info.value}`}
                                                                                    </Typography>
                                                                                )}
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Avatar src={contact.profilePicUrl} />
                                                                    </AvatarTooltip>
                                                                    : <Avatar src={contact.profilePicUrl} />
                                                                }
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography
                                                                    noWrap
                                                                    style={{ maxWidth: 150, fontSize: 14 }}
                                                                >   
                                                                    {contact.name}
                                                                    {contact.company &&
                                                                    <div>
                                                                        <Typography
                                                                        noWrap
                                                                                style={{ marginTop:"-5px", maxWidth: 150, fontSize: 12 }}
                                                                                color="textSecondary"
                                                                        >
                                                                            {`${contact.company}`}
                                                                        </Typography>
                                                                    </div>
                                                                    }
                                                                    <div className={classes.chipContainer}>
                                                                        <TagsWrapper tags={contact.tags} maxWidth={120} />
                                                                    </div>
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">{contact.number} </TableCell>
                                                        </TableRow>

                                                    ))}{loadingContacts && <TableRowSkeleton checkboxes={1} avatar={1} columns={2} />}
                                            </TableBody>
                                        </Table>
                                    </FormGroup>
                                </div>


                            </Grid>
                            <Sidebar />
                        </Grid>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("tags.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        onClick={handleAddContacts}
                        color="primary"
                        disabled={loading}
                        loading={loading}
                    >
                        {i18n.t("tags.buttons.add")}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default TagContactsModal;