import React, { useState, useEffect, useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import toastError from "../../errors/toastError";
import { CircularProgress } from "@mui/material";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import Title from "../../components/Title";
import Markdown from "markdown-to-jsx";


const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        margin: theme.spacing(1),
        marginTop: 0,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const Changelog = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const isMounted = useRef(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        const fetchSession = async () => {
            try {
                const { data } = await api.get("/changelog");
                setData(data);
            } catch (err) {
                toastError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchSession();
    }, []);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("changelog.title")}</Title>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                
                        <Typography variant="body1">
                            {`Versão atual: v${data?.version||"0.0.0"}`}
                        </Typography>
                        <Divider />
                        {!loading && data.markdownContent ? 
                        <div style={{padding: 10}}>
                    <Markdown>{data?.markdownContent}</Markdown>
                </div>
                    : <Typography variant="body1">
                        {loading ?
                            <>
                                <CircularProgress size={24} />
                                {i18n.t("changelog.loading")}
                            </>
                            : <>{i18n.t("changelog.loadfail")}</>}
                    </Typography>}
                        
                    

            </Paper>
        </MainContainer>
    );
};

export default Changelog;
