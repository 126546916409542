import {
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    InputBase,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AccessTime, Close, Description, Done, DoneAll, Mic, People, Person, PhotoCamera, Search, Videocam } from "@mui/icons-material";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { i18n } from "../../translate/i18n";
import { SearchContext } from "../../context/Search/SearchContext";
import api from "../../services/api";
import MarkdownWrapper from "../MarkdownWrapper";
import { format, isSameDay, parseISO } from "date-fns";
import { cyan } from "@mui/material/colors";
import { toast } from "react-toastify";


const reducer = (state, action) => {
    if (action.type === "LOAD_MESSAGES") {
        const messages = action.payload;
        const newMessages = [];

        messages.forEach((message) => {
            const messageIndex = state.findIndex((m) => m.id === message.id);
            if (messageIndex !== -1) {
                state[messageIndex] = message;
            } else {
                newMessages.push(message);
            }
        });

        return [...state, ...newMessages];
    }

    if (action.type === "ADD_MESSAGE") {
        const newMessage = action.payload;
        const messageIndex = state.findIndex((m) => m.id === newMessage.id);

        if (messageIndex !== -1) {
            state[messageIndex] = newMessage;
        } else {
            state.push(newMessage);
        }

        return [...state];
    }
    function ToastDisplay(props) {
        return <><h4>Mensagem apagada:</h4><p>{props.body}</p></>;
    }


    if (action.type === "UPDATE_MESSAGE") {
        const messageToUpdate = action.payload;
        const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

        if (messageToUpdate.isDeleted === true) {
            //toast.info(`Mensagem apagada: ${messageToUpdate.body}  `,{autoClose: false});
            toast.info(<ToastDisplay
                body={messageToUpdate.body}
            >
            </ToastDisplay>, { autoClose: false });
        }

        if (messageIndex !== -1) {
            state[messageIndex] = messageToUpdate;
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};
const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    header: {
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: theme.palette.background.header,
        alignItems: "center",
        padding: theme.spacing(0, 1),
        minHeight: "73px",
        justifyContent: "flex-start",
    },
    content: {
        display: "flex",
        backgroundColor: theme.palette.background.list,
        flexDirection: "column",
        height: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    searchheader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: 0,
        background: theme.palette.background.list,
    },
    serachInputWrapper: {
        flex: 1,
        background: theme.palette.background.header,
        display: "flex",

        borderRadius: 11,
        padding: 4,
        margin: theme.spacing(1),
    },

    searchIcon: {
        color: theme.palette.text.secondary,
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },

    searchInput: {
        flex: 1,
        border: 0,
    },
    openSearch: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "150px",
        textAlign: "center",
        borderRadius: 0,
    },
    divider: {
        backgroundColor: theme.palette.border,
    },
    messageList: {
        backgroundColor: "transparent",
    },
    contactNameWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    ackIcons: {
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },
    ackDoneAllIcon: {
        color: cyan[500],
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },
    loadingWrapper:{
        display:"flex",
        padding: "10px",
        justifyContent: "space-evenly"
    }
}));

const SearchMessages = ({ handleDrawerClose, ticketId, ticket }) => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setSearchMessage, isSearching } = useContext(SearchContext)
    const [foundMessages, dispatch] = useReducer(reducer, []);

    const searchInputRef = useRef();

    useEffect(() => {
        setTimeout(() =>
            searchInputRef.current.focus()
            , 500)
    }, [isSearching]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, ticketId]);

    useEffect(() => {
        setLoading(true);
        if (searchParam.trim()) {

            const fetchMessages = async () => {
                const { data } = await api.get(`/messages/${ticketId}`, { params: { searchParam, pageNumber, showAllMessages: true } });
                dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
                setHasMore(data.hasMore);
                setLoading(false);
            }
            fetchMessages();
        } else {
            dispatch([]);
            setLoading(false);
        }

    }, [searchParam, pageNumber, ticketId]);

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            e.currentTarget.scrollTop = scrollTop - 100;
            loadMore();
        }
    };

    const renderMessageAck = (message) => {
        if (message.ack === 0) {
            return <AccessTime fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 1) {
            return <Done fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 2) {
            return <DoneAll fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 3 || message.ack === 4) {
            return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
        }
    };

    const renderMediaType = (message) => {
        if (message.mediaType === "image") {
            return <PhotoCamera fontSize="small" className={classes.ackIcons} />;
        }
        if (message.mediaType === "audio") {
            return <Mic fontSize="small" className={classes.ackIcons} />;
        }
        if (message.mediaType === "video") {
            return <Videocam fontSize="small" className={classes.ackIcons} />;
        }
        if (message.mediaType === "application" || message.mediaType === "text") {
            return <Description fontSize="small" className={classes.ackIcons} />;
        }
        if (message.mediaType === "vcard") {
            const array = message.body.split("\n");
            const obj = [];
            let contact = "";
            for (let index = 0; index < array.length; index++) {
                const v = array[index];
                let values = v.split(":");
                for (let ind = 0; ind < values.length; ind++) {
                    if (values[ind].indexOf("+") !== -1) {
                        obj.push({ number: values[ind] });
                    }
                    if (values[ind].indexOf("FN") !== -1) {
                        contact = values[ind + 1];
                    }
                }
            }
            return (
                <>
                    <Person style={{ height: "16px" }} />
                    <MarkdownWrapper>{`Contato: ${contact}`}</MarkdownWrapper>
                </>);
        }
        if (message.mediaType === "multi_vcard") {
            const cleanedString = message.body.replace(/\\n/g, '\n'); // Restaurar as quebras de linha
            const array = cleanedString.toString().split("END:VCARD");
            const obj = [];
            for (let i = 0; i < array.length; i++) {
                const vCard = array[i];
                const vCardLines = vCard.split("\n");
                let name = "";
                let number = "";
                for (let j = 0; j < vCardLines.length; j++) {
                    const line = vCardLines[j];
                    if (line.startsWith("FN:")) {
                        name = line.slice(3);
                    } else if (line.startsWith("item1.TEL;waid=")) {
                        const phoneNumber = line.split(":")[1];
                        number = phoneNumber;
                    }
                }
                if (name !== "" && number !== "") {
                    obj.push({
                        name,
                        number
                    });
                }
            }
            return (
                <>
                    <People fontSize="small" className={classes.ackIcons} />
                    <MarkdownWrapper>{` ${obj?.length} contatos`}</MarkdownWrapper>
                </>
            );
        }
    }

    let searchTimeout;
    const handleSearch = (e) => {
        const searchedTerm = e.target.value.toLowerCase();

        clearTimeout(searchTimeout);

        if (searchedTerm === "") {
            setSearchParam(searchedTerm);
            dispatch('')
            return;
        }

        searchTimeout = setTimeout(() => {
            setSearchParam(searchedTerm);
        }, 500);
    };

    const handleSelectMessage = (message)=>{
        setSearchMessage(message)
    }



    return <>
        <div className={classes.header}>
            <IconButton className={classes.iconButton} onClick={handleDrawerClose} size="large">
                <Close />
            </IconButton>
            <Typography style={{ justifySelf: "center" }}>
                {i18n.t("searchDrawer.header")}
            </Typography>
        </div>
        <Paper square variant="outlined" className={classes.searchheader}>
            <div className={classes.serachInputWrapper}>
                <Search className={classes.searchIcon} />
                <InputBase
                    className={classes.searchInput}
                    inputRef={searchInputRef}
                    placeholder={i18n.t("Pesquisar...")}
                    type="search"
                    onChange={handleSearch}
                />
            </div>
        </Paper>
        <Divider className={classes.divider} />
        <div className={classes.content} onScroll={handleScroll}>
            {searchParam.trim() ?
            <>
                    {foundMessages && foundMessages.map((message) =>
                        <div>
                            <ListItemButton
                                dense
                                component="a"
                                onClick={e => handleSelectMessage(message)}
                            >
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <span className={classes.contactNameWrapper}>
                                            <Typography
                                                noWrap
                                                component="span"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {isSameDay(parseISO(message.createdAt), new Date()) ? (
                                                    <>{format(parseISO(message.createdAt), "HH:mm")}</>
                                                ) : (
                                                        <>{format(parseISO(message.createdAt), "dd/MM/yyyy")}</>
                                                )}
                                            </Typography>

                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textSecondary"
                                            >

                                            </Typography>

                                        </span>
                                    }

                                    secondary={<>
                                        <span className={classes.contactNameWrapper}>
                                            <Typography
                                                className={classes.contactLastMessage}
                                                noWrap
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {message.fromMe ? renderMessageAck(message) : <></>}
                                                {renderMediaType(message)}

                                                <MarkdownWrapper>{ticket.isGroup && !message.fromMe ? `${message.contact?.name}: ${message.body}` : ` ${message.body}`}</MarkdownWrapper>

                                            </Typography>
                                        </span>

                                    </>
                                    }
                                />


                            </ListItemButton>

                            <Divider className={classes.divider} />
                        </div>
                    )}
                    {loading && <div className={classes.loadingWrapper}><CircularProgress size={24} color="primary" />
                    </div>}
                    </>
                : <div className={classes.openSearch}>
                    <Typography
                    color="textSecondary"
                        variant="body2"
                    >
                        {i18n.t("Pesquisar por mensagens em {{contactName}}", { contactName: ticket.contact.name })}
                    </Typography>
                </div>
            }
        </div>
    </>;
}
export default SearchMessages;